import { ContentSection } from 'designsystem/components/4-habitats/ContentSection';
import { ModuleFeature } from 'features/Modules/DynamicModule';
export const SingleContentSectionFeature = ({
	pageSection,
	noBackground = false,
}: {
	pageSection: Content.ContentModule[];
	noBackground?: boolean;
}) => {
	{
		const noTopMargin = pageSection?.[0]?.documentType === 'calculatorSelector';
		return pageSection?.length > 0 ? (
			<ContentSection noTopMargin={noTopMargin}>
				{pageSection.map((module, index) => (
					<ModuleFeature key={index} contentModule={module} additionalProps={{ noBackground }} area="contentSection" />
				))}
			</ContentSection>
		) : null;
	}
};
