import classNames from 'classnames';
import * as React from 'react';
import styles from './TopArea.module.scss';

export interface TopAreaProps {
	additional?: {
		top?: React.ReactNode;
		bottom?: React.ReactNode;
	};
	additionalNoPadding?: boolean;
	topBanner?: React.ReactNode;
}

export const TopArea: React.FC<TopAreaProps> = ({ additional, additionalNoPadding, topBanner }) => {
	return (
		<>
			{additional && (
				<>
					<div className={styles.TopArea_additional}>
						{additional?.top && (
							<div
								className={classNames(
									styles.TopArea_additionalWrapper,
									styles.TopArea_additionalWrapper___top,
									additionalNoPadding && styles.TopArea_additionalWrapper___noPadding,
								)}
							>
								<div className={styles.TopArea_additionalContainer}>{additional.top}</div>
							</div>
						)}
						{additional?.bottom && (
							<div
								className={classNames(
									styles.TopArea_additionalWrapper,
									styles.TopArea_additionalWrapper___bottom,
									additionalNoPadding && styles.TopArea_additionalWrapper___noPadding,
								)}
							>
								<div className={styles.TopArea_additionalContainer}>{additional.bottom}</div>
							</div>
						)}
					</div>
				</>
			)}
			{topBanner && (
				<div className={classNames(styles.TopArea_banner, additional?.bottom && styles.TopArea_banner___noTopMargin)}>{topBanner}</div>
			)}
		</>
	);
};
