import classNames from 'classnames';
import { Container, ContainerWidth } from 'components/4-habitats/Container';
import { TopArea, TopAreaProps } from 'components/4-habitats/TopArea';
import styles from './ContentPage.module.scss';

export interface ContentPageProps {
	additionalTop?: TopAreaProps['additional'];
	additionalTopSettings?: {
		additionalNoPadding?: boolean;
	};
	additionalPageContent?: React.ReactNode;
	header?: React.ReactNode;
	topBanner?: React.ReactNode;
	pageWidth?: ContainerWidth;
	children?: React.ReactNode;
}

export const ContentPage: React.FC<ContentPageProps> = ({
	additionalTop,
	additionalTopSettings,
	additionalPageContent,
	children,
	header,
	topBanner,
	pageWidth = 'Medium',
}) => {
	return (
		<div className={styles.ContentPage}>
			<TopArea additional={{ top: additionalTop?.top }} topBanner={topBanner} {...additionalTopSettings} />
			{additionalPageContent}
			{additionalTop?.bottom && (
				<Container className={styles.ContentPage_topWrapper} width={pageWidth} padded>
					{additionalTop.bottom}
				</Container>
			)}

			{header && (
				<div
					className={classNames(
						styles.ContentPage_wrapper,
						{ [styles.ContentPage_wrapper___noTopMargin]: additionalTop?.bottom || !!additionalPageContent },
						{ [styles.ContentPage_wrapper___medium]: pageWidth === 'Medium' },
						{ [styles.ContentPage_wrapper___large]: pageWidth === 'Large' },
						{ [styles.ContentPage_wrapper___xlarge]: pageWidth === 'X-Large' },
					)}
				>
					<header>{header}</header>
				</div>
			)}
			<Container width={pageWidth} padded>
				{children && children}
			</Container>
		</div>
	);
};
