import { ModuleFeature } from '../DynamicModule';
export const TopBannerFeature = ({ bannerContent }: Content.TopBanner) => {
	return (
		<>
			{bannerContent?.map((bannerModule, index) => {
				const bannerCardProps = bannerModule.content.documentType === 'bannerCard' ? { headingLevel: 'h2' } : undefined;
				return (
					<ModuleFeature
						key={index}
						contentModule={bannerModule}
						additionalProps={bannerCardProps}
						area="topBanner"
						className={index === 0 && 'u-top-margin-important--none u-bottom-margin-important--lg'}
					/>
				);
			})}
		</>
	);
};
