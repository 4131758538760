import classNames from 'classnames';
import styles from './ContentSection.module.scss';

export interface ContentSectionProps {
	className?: string;
	children?: React.ReactNode;
	title?: string;
	noTopMargin?: boolean;
}

export const ContentSection: React.FC<ContentSectionProps> = ({ children, className, title, noTopMargin }) => {
	return (
		<section aria-label={title} className={classNames(styles.ContentSection, noTopMargin && styles.ContentSection___noTopMargin, className)}>
			{children}
		</section>
	);
};
