import classNames from 'classnames';
import styles from './Container.module.scss';

export type ContainerWidth = 'Small' | 'Medium' | 'Large' | 'X-Large' | '';

export interface ContainerProps {
	className?: string;
	width?: ContainerWidth;
	children: React.ReactNode;
	padded?: boolean;
	margined?: boolean;
}

export const Container: React.FC<ContainerProps> = ({ className, width, children, padded, margined }) => {
	return (
		<div
			className={classNames(
				styles.Container,
				{ [styles.Container___small]: width === 'Small' },
				{ [styles.Container___medium]: width === 'Medium' },
				{ [styles.Container___large]: width === 'Large' },
				{ [styles.Container___xlarge]: width === 'X-Large' },
				padded && styles.Container___padded,
				margined && styles.Container___margined,
			)}
		>
			<div className={classNames(styles.Container_wrapper, className)}>{children}</div>
		</div>
	);
};
