import { ContentPage } from 'designsystem/components/5-templates/ContentPage';
import { SingleContentSectionFeature } from 'features/Modules/ContentSection/SingleContentSectionFeature';
import { ModuleFeature } from 'features/Modules/DynamicModule';
import { TopBannerFeature } from 'features/Modules/TopBanner';

export const HomePageFeature: React.FC<Content.HomePage> = ({ properties, children, additionalTop, additionalPageContent }) => {
	const { pageHeader, pageSection, pageSections, bannerContent } = properties ?? {};
	return (
		<ContentPage
			additionalTop={additionalTop}
			additionalPageContent={additionalPageContent}
			topBanner={bannerContent?.length > 0 && <TopBannerFeature bannerContent={bannerContent} />}
			header={
				pageHeader?.length > 0 &&
				pageHeader.map((headerModule, index) => (
					<ModuleFeature key={index} contentModule={headerModule} isHeader className={index === 0 && 'u-top-margin-important--none'} />
				))
			}
		>
			{children}
			<SingleContentSectionFeature pageSection={pageSection} />

			{pageSections?.length > 0 && pageSections.map((sectionModule, index) => <ModuleFeature key={index} contentModule={sectionModule} />)}
		</ContentPage>
	);
};
